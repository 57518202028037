import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import LayoutWithImage from "../components/layout/LayoutWithImage"

const LinksPage = () => (
  <Layout>
    <SEO title="Links" />

    <div className="pt-5 header">
      <h1>Links</h1>
      <h2>Links zu Webseiten mit interessanten Informationen.</h2>
    </div>

    <LayoutWithImage>
      <p>
        Auf dieser Seite führt &laquo;sichtwechsel&raquo; interessante Links rund um das
        Thema Menschen mit Behinderung und Unterstützungsbedarf auf.
      </p>

      <h3 className="mt-0">Beratung und Hilfestellung</h3>
      <a className="d-block" href="http://www.abb.llv.li" target="_blank" rel="external">Amt für Berufsbildung und Berufsberatung</a>
      <a className="d-block" href="http://www.asd.llv.li" target="_blank" rel="external">Amt für Soziale Dienste</a>
      <a className="d-block" href="http://www.ahv.li/leistungen/iv/allgemeines.html?F=0print%2Fprint%2Fprint%2Fprint%2Fprint.html" target="_blank" rel="external">Alters- und Hinterlassenenversicherung</a>
      <a className="d-block" href="http://www.lbv.li/Leichte-Sprache.php" target="_blank" rel="external">Büro für Leichte Sprache</a>
      <a className="d-block" href="https://www.demenz.li/" target="_blank" rel="external">Demenz Liechtenstein</a>
      <a className="d-block" href="http://www.familienhilfe.li" target="_blank" rel="external">Familienhilfe Liechtenstein</a>
      <a className="d-block" href="http://www.fragile-ostschweiz.ch" target="_blank" rel="external">Fragile Ostschweiz</a>
      <a className="d-block" href="http://www.deaf.li" target="_blank" rel="external">Gehörlosen Kulturverein Liechtenstein</a>
      <a className="d-block" href="http://www.krebshilfe.li" target="_blank" rel="external">Krebshilfe Liechtenstein</a>
      <a className="d-block" href="http://www.lbv.li" target="_blank" rel="external">Liechtensteiner Behinderten-Verband</a>
      <a className="d-block" href="http://www.behinderung.li" target="_blank" rel="external">Linksammlung zum Thema Behinderung</a>
      <a className="d-block" href="http://www.netzwerk.li" target="_blank" rel="external">NetzWerk</a>
      <a className="d-block" href="http://www.oskj.li" target="_blank" rel="external">Ombudsstelle für Kinder und Jugendliche</a>
      <a className="d-block" href="http://www.obvita.ch" target="_blank" rel="external">Ostschweizerischer Blindenfürsorgeverein</a>
      <a className="d-block" href="http://www.multiplesklerose.ch" target="_blank" rel="external">Schweizerische Multiple Sklerose Gesellschaft</a>
      <a className="d-block" href="http://www.sgb-fss.ch" target="_blank" rel="external">Schweizerischer Gehörlosenbund</a>
      <a className="d-block" href="http://www.unanders.li" target="_blank" rel="external">Selbsthilfegruppe 'unanders'</a>
      <a className="d-block" href="http://www.trialog-liechtenstein.li" target="_blank" rel="external">Trialog Liechtenstein</a>
      <a className="d-block" href="https://www.menschenrechte.li/" target="_blank" rel="external">Verein für Menschenrechte</a>

      <h3>Freizeit</h3>
      <a className="d-block" href="https://www.lbv.li/Freizeit-regelmaessig.php" target="_blank" rel="external">Freizeitangebote LBV</a>
      <a className="d-block" href="http://www.jungestheater.li" target="_blank" rel="external">junges Theater liechtenstein</a>
      <a className="d-block" href="http://www.ludothek.li" target="_blank" rel="external">Ludothek Fridolin</a>

      <h3>Hilfsmittel</h3>
      <a className="d-block" href="http://www.orthocenter.ch/" target="_blank" rel="external">bente Orthopädie und Rehatechnik</a>
      <a className="d-block" href="http://www.treppenliftfirmen.de" target="_blank" rel="external">Firmen die Treppenlifte herstellen</a>


      <h3>Schule und Wohnen</h3>

      <a className="d-block" href="http://www.hpz.li" target="_blank" rel="external">Heilpädagogisches Zentrum</a>
      <a className="d-block" href="http://www.sa.llv.li" target="_blank" rel="external">Schulamt</a>
      <a className="d-block" href="http://www.vbw.li" target="_blank" rel="external">Verein für Betreutes Wohnen</a>

      <h3>Sport</h3>
      <a className="d-block" href="http://www.specialolympics.li" target="_blank" rel="external">Special Olympics</a>

      <h3>Weitere Links</h3>
      <a className="d-block" href="http://www.aaa.llv.li" target="_blank" rel="external">Amt für Auswärtige Angelegenheiten</a>
      <a className="d-block" href="http://www.liechtenstein-institut.li" target="_blank" rel="external">Liechtenstein-Institut</a>
      <a className="d-block" href="http://www.regierung.li/ministerien/ministerium-fuer-gesellschaft/" target="_blank" rel="external">Ministerium für Gesellschaft</a>
    </LayoutWithImage>
  </Layout>
)

export default LinksPage
